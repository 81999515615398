<template>
  <div class="card-ticker">
    <div
      class="card-ticker__element card-ticker__element--ticker"
      :class="elementClasses"
    />
    <div
      class="card-ticker__element card-ticker__element--name"
      :class="elementClasses"
    />
    <div
      class="card-ticker__element card-ticker__element--value"
      :class="elementClasses"
    >
      <div class="card-ticker__element--amount"></div>
      <div class="card-ticker__element--percent"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  static?: boolean
}

const props = defineProps<Props>()

const elementClasses = computed(() => ({
  'card-ticker__element--static': props.static,
}))
</script>

<script lang="ts">
export default {
  name: 'UISkeletonCardTicker',
}
</script>

<style lang="postcss">
.card-ticker {
  @apply w-40 sm:w-48 min-w-max;
  @apply shrink-0;
  @apply p-4 space-y-2;
  @apply bg-white dark:bg-gray-850;
  @apply shadow;
  @apply rounded-lg;

  &__element {
    @apply animate-pulse;
    @apply rounded;

    &--static {
      @apply animate-none;
    }

    &--ticker {
      @apply h-[1.4rem] max-w-[4rem];
      @apply bg-gray-300 dark:bg-gray-700;
    }

    &--name {
      @apply h-[1rem] max-w-[7rem];
      @apply bg-gray-50 dark:bg-gray-700;
      @apply animation-delay-200;
    }

    &--value {
      @apply flex justify-between;
    }

    &--amount,
    &--percent {
      @apply h-[1.6rem];
      @apply rounded;
      @apply bg-gray-100 dark:bg-gray-700;
    }

    &--amount {
      @apply w-1/2;
    }

    &--percent {
      @apply w-[2rem];
    }
  }
}
</style>
