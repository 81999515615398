<template>
  <div class="skeleton-chart-pie">
    <div
      class="skeleton-chart-pie__piece skeleton-chart-pie__piece--tl"
      :class="pieceClasses"
    />
    <div
      class="skeleton-chart-pie__piece skeleton-chart-pie__piece--tr"
      :class="pieceClasses"
    />
    <div
      class="skeleton-chart-pie__piece skeleton-chart-pie__piece--bl"
      :class="pieceClasses"
    />
    <div
      class="skeleton-chart-pie__piece skeleton-chart-pie__piece--br"
      :class="pieceClasses"
    />
    <div v-if="!static" class="skeleton-chart-pie__message">{{ message }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  message?: string
  static?: boolean
}

const { message = 'Chart is loading...', static: isStatic } =
  defineProps<Props>()

const pieceClasses = computed(() => ({
  'skeleton-chart-pie__piece--static': isStatic,
}))
</script>

<script lang="ts">
export default {
  name: 'UISkeletonChartPie',
}
</script>

<style lang="postcss">
.skeleton-chart-pie {
  @apply relative;
  @apply w-[12.5rem] h-[12.5rem];
  @apply rounded-full;
  @apply mx-auto;
  @apply overflow-hidden;

  &__piece {
    @apply absolute;
    @apply bg-gray-300 dark:bg-gray-700;
    @apply animate-pulse;
    @apply second:animation-delay-200;
    @apply third:animation-delay-400;
    @apply fourth:animation-delay-600;

    &--static {
      @apply animate-none;
    }

    &--tl,
    &--tr {
      @apply top-0 bottom-1/2;
      @apply mb-[0.05rem];
    }

    &--bl,
    &--br {
      @apply bottom-0 top-1/2;
      @apply mt-[0.05rem];
    }

    &--tl,
    &--bl {
      @apply left-0 right-1/2;
      @apply mr-[0.05rem];
    }

    &--tr,
    &--br {
      @apply right-0 left-1/2;
      @apply ml-[0.05rem];
    }
  }

  &__message {
    @apply absolute top-1/2 left-1/2;
    @apply -translate-x-1/2 -translate-y-1/2;
    @apply text-xs;
    @apply text-gray-400 dark:text-gray-500;
    @apply text-center;
  }
}
</style>
