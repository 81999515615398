<template>
  <div class="skeleton-pivot">
    <div class="skeleton-pivot__sidebar" :class="sidebarClasses" />
    <div class="skeleton-pivot__main">
      <div class="skeleton-pivot__header" :class="headerClasses" />
      <div class="skeleton-pivot__cells">
        <div
          v-for="n in 5"
          :key="n"
          class="skeleton-pivot__head"
          :class="headClasses"
        />
      </div>
      <div v-for="n in 4" :key="n" class="skeleton-pivot__cells">
        <div
          v-for="m in 5"
          :key="m"
          class="skeleton-pivot__cell"
          :class="cellClasses"
        />
      </div>
      <div class="skeleton-pivot__cells">
        <div
          v-for="n in 5"
          :key="n"
          class="skeleton-pivot__head"
          :class="headClasses"
        />
      </div>
    </div>
    <div v-if="!static" class="skeleton-pivot__message">{{ message }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  message?: string
  static?: boolean
}

const { message = 'Pivot is loading...', static: isStatic } =
  defineProps<Props>()

defineOptions({
  name: 'UISkeletonPivot',
})

const sidebarClasses = computed(() => ({
  'skeleton-pivot__sidebar--static': isStatic,
}))

const headerClasses = computed(() => ({
  'skeleton-pivot__header--static': isStatic,
}))

const headClasses = computed(() => ({
  'skeleton-pivot__head--static': isStatic,
}))

const cellClasses = computed(() => ({
  'skeleton-pivot__cell--static': isStatic,
}))
</script>

<style>
.skeleton-pivot {
  @apply flex flex-col sm:flex-row flex-auto;
  @apply gap-1 sm:gap-2;

  &__sidebar {
    @apply sm:w-1/4 sm:max-w-48;
  }

  &__main {
    @apply flex flex-col flex-auto;
    @apply gap-1;
  }

  &__header {
    @apply h-8;
    @apply shrink-0;
  }

  &__cells {
    @apply flex;
    @apply gap-1;
  }

  &__head,
  &__cell {
    @apply w-full max-w-20;
    @apply aspect-[16/6];
  }

  &__cell {
    @apply bg-gray-50 dark:bg-gray-700;
  }

  &__sidebar,
  &__header,
  &__head {
    @apply bg-gray-100 dark:bg-gray-800;
  }

  &__sidebar,
  &__header,
  &__head,
  &__cell {
    @apply animate-pulse;
    @apply second:animation-delay-200;
    @apply third:animation-delay-400;
    @apply fourth:animation-delay-600;

    &--static {
      @apply animate-none;
    }
  }

  &__message {
    @apply absolute top-1/2 left-1/2;
    @apply -translate-x-1/2 -translate-y-1/2;
    @apply text-xs;
    @apply text-gray-400 dark:text-gray-500;
    @apply text-center;
  }
}
</style>
